<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <a class="navbar-brand" href="/">
                <img src="@/assets/images/logo.png" alt="Logo" class="d-inline-block align-text-top">
            </a>
            <span class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i class="bi bi-list navbar-menu-toggler"></i>
            </span>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="navbar-item me-4 align-self-center">
                        <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
                    </li>
<!--                    <li class="navbar-item me-4 align-self-center">-->
<!--                        <router-link :to="{name: 'Mortgage'}" class="nav-link">Mortgage Payment</router-link>-->
<!--                    </li>-->
                    <li class="navbar-item me-4 align-self-center">
                        <router-link to="/faq" class="nav-link">FAQS</router-link>
                    </li>
                    <li class="navbar-item me-4 align-self-center">
                        <router-link :to="{name: 'Contact'}" class="nav-link">Get In Touch</router-link>
                    </li>
                    <li class="navbar-item me-4 align-self-center">
                        <router-link to="/blogs" class="nav-link">Blogs</router-link>
                    </li>
                    <li class="navbar-item ms-md-4 text-center">
                        <a class="btn btn__gradient" href="#signup">Get started</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Header',
}
</script>
