<template>
    <div class="wrapper">
        <!-- Header -->
        <Header/>
        <!-- End Header -->
        <div class="faq-wrapper bg-gradient">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="text-white">Frequently asked questions</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-padding" id="faqs">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 mx-auto">
                        <div class="faq-body section-space--mt_40">
                            <div class="accordion" id="accordionExample">
                                <div class="card" v-for="(faq, index) in faqs" :key="index">
                                    <div class="card-header" :id="'heading' + index">
                                        <h5 class="mb-0">
                                            <button class="collapsed" data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapse' +  index" aria-expanded="false"
                                                    :aria-controls="'collapse' +  index">
                                                {{ faq.question }}
                                                <span>
                                                    <i class="bi bi-chevron-down"></i>
                                                    <i class="bi bi-chevron-up"></i>
                                                </span>
                                            </button>
                                        </h5>
                                    </div>
                                    <div :id="'collapse' + index" class="accordion-collapse collapse"
                                         :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>{{ faq.answer }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- App Store -->
        <AppStore/>
        <!-- End App Store -->
        <!-- Footer -->
        <Footer/>
        <!-- End Footer -->
    </div>
</template>

<script>

import Header from "@/components/elements/Header";
import Footer from "@/components/elements/Footer";
import AppStore from "@/components/AppStore";
import {appName} from "@/utils/variables";

export default {
    name: "Faq",
    components: {Header, Footer, AppStore},
    data() {
        return {
            faqs: [
                {
                    question: `What is ${appName}?`,
                    answer: `${appName} is a Peer to Peer (P2P) currency exchange solution that provides users the
                    opportunity to save money in stable currencies and exchange funds at affordable rates as a measure
                    against devaluation and fraught exchange protocols. ${appName} is the first to allow rate negotiation.`
                },
                {
                    question: `Who can use ${appName}?`,
                    answer: `${appName} is perfect for anyone looking for an easy, quick, secure currency exchange/transfer
                    experience.<br>It is especially helpful to support the financial needs of newcomers who have recently
                    relocated, students, and frequent travelers.`
                },
                {
                    question: 'How does the transfer process work',
                    answer: `Once your profile has been successfully verified, performing a transfer is quick and easy.
                    Clients must first create, negotiate or accept a deal from our pool of rates. In order to
                    transfer funds on ${appName}, the sender and receiver's bank account information will need to be
                    provided on the platform. When the bank account information is added and a deal has been accepted,
                    this automatically triggers our backend systems to accept/remit the funds. When your funds have been
                     transferred, you will receive a notification confirming the completed transaction.`
                },
                {
                    question: 'Where can I find this app?',
                    answer: `The ${appName} platform is available for download on the App Store for IOS devices and Play
                    Store for Android devices.`
                },
                {
                    question: 'How can I see all my transaction?',
                    answer: `___`
                },
                {
                    question: 'Where can I see my wallet balance?',
                    answer: `____`
                },
                {
                    question: `Can I have multiple accounts on ${appName}?`,
                    answer: `___`
                },
                {
                    question: `Is ${appName} available on every country?`,
                    answer: `___`
                },
                {
                    question: 'Who are your target consumers?',
                    answer: `___`
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>