<template>
    <div class="wrapper">
        <!-- Header -->
        <Header/>
        <!-- End Header -->
        <!-- Hero Banner -->
        <HeroBanner :is-sell="sell"/>
        <!-- End Hero Banner -->
        <!-- Our Market -->
        <div class="section-padding">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-10 mx-auto text-center">
                        <h2 class="fs-38 fw-bolder pb-4 text__black">
                            See what our <span class="text__gradient">Market</span> is saying
                        </h2>
                        <p>
                            Select a payment method you like and trade directly with other people just like you!
                        </p>
                        <div>
                            <label class="btn btn-bottom-line mx-3" :class="{active : !sell}" @click="showBuy">
                                Buy
                            </label>
                            <label class="btn btn-bottom-line mx-3" :class="{active : sell}" @click="showSell">
                                Sell
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 col-md-12 mx-auto">
                        <MarketChart :items="items" v-show="sell" :is-sell="sell"/>
                        <MarketChart :items="items" v-show="!sell" :is-sell="sell"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Our Market -->
        <!-- Why Us -->
        <div class="section-padding">
            <div class="container">
                <div class="row m-0">
                    <div class="col-md-9 mb-4">
                        <h2 class="fs-38 fw-bolder pb-4 text__black">
                            Why <span class="text__gradient">{{ appName }}</span>?
                        </h2>
                        <p>
                           Our service enables you to not only decide on your exchange vendor<br/> but also choose the
                            rate that is better suited for your pocket, <br/>which simplifies money transfers across
                            countries.
                        </p>
                    </div>
                    <div class="col-md-3 justify-items-end mb-4">
                        <div class="mt-4">
                            <a class="btn btn__gradient" href="#signup">Get started</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-4" v-for="(service, index) in services" :key="index">
                        <div class="card card-no-border card__bg-gray py-5 br-40 card__full">
                            <div class="mb-4">
                                <i class="bi icon" :class="[service.iconName, service.iconColor]"></i>
                            </div>
                            <p class="fw-600 text__black fs-20 py-3">{{ service.title }}</p>
                            <p  v-html="service.body"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Why Us -->
        <!-- How it works -->
        <div class="section-padding">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-lg-6 col-md-10 mx-auto text-center">
                        <h2 class="fs-38 fw-bolder pb-4 text__black">
                            How to create a free account on  <span class="text__gradient">{{ appName }}</span>
                        </h2>
                        <p>
                            Opening an account has never been this seamless
                        </p>
                    </div>
                </div>
                <div class="row gx-md-5">
                    <div class="col-md-4 text-center mb-4" v-for="(step, index) in steps" :key="index">
                        <div class="card__full">
                            <img :src="require('../assets/images/' + step.imgPath)" alt="account" height="150px">
                            <p class="fw-600 text__primary fs-24 py-3">{{ step.title }}</p>
                            <p  v-html="step.body"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End How it works -->
        <!-- App Store -->
        <AppStore/>
        <!-- End App Store -->
        <!-- Footer -->
        <Footer/>
        <!-- End Footer -->
    </div>
</template>

<script>

import Header from "@/components/elements/Header";
import HeroBanner from "@/components/HeroBanner";
import MarketChart from "@/components/MarketChart";
import Footer from "@/components/elements/Footer";
import AppStore from "@/components/AppStore";
import {appName} from "@/utils/variables";

export default {
    name: "Home",
    components: {AppStore, Footer, MarketChart, HeroBanner, Header},
    data() {
        return {
            appName,
            checked: false,
            sell: false,
            items: [
                {
                    user: 'mikkyme',
                    amount: '650',
                    payment_method: "Card",
                    available_amount: '2000'
                },
                {
                    user: 'jamesbrown',
                    amount: '630',
                    payment_method: "Bank Transfer",
                    available_amount: '1000'
                },
                {
                    user: 'adorable',
                    amount: '590',
                    payment_method: "Bank Transfer",
                    available_amount: '5000'
                },
                {
                    user: 'lifeofadaa',
                    amount: '599',
                    payment_method: "Card",
                    available_amount: '2000'
                }
            ],
            steps: [
                {
                    imgPath: 'register.png',
                    title: 'Create an Account',
                    body: `Sign up online, or in our app with your email address`
                },
                {
                    imgPath: 'verify.png',
                    title: 'Verify your Email',
                    body: `Click on the link sent to your email to verify your email address`
                },
                {
                    imgPath: 'password.png',
                    title: 'Create your secure Pin',
                    body: `Create a secure PIN to be used to carry out transaction`
                }
            ],
            services: [
                {
                    iconName: 'bi-arrow-left-right',
                    iconColor: 'primary',
                    title: 'Fast Transaction',
                    body: `End users are saved the stress of dealing with the middle-man hassle, for a seamless transaction
                    with splendid rates and low-interest fees while saving time and gaining more.`
                },
                {
                    iconName: 'bi-shield-lock',
                    iconColor: 'pink',
                    title: 'Peer-to-Peer Open Market',
                    body: `Our P2P platform actively utilizes our user network and provides you with varied 
                    options of competitive rates and prices and the capacity to be not just a buyer but a seller of currencies.`
                },
                // {
                //     iconName: 'bi-bank',
                //     iconColor: 'orange',
                //     title: 'Simplified Mortgage Payments',
                //     body: `Managing your mortgage payments has never been easier. We are partnering with mortgage banks
                //     and real estate developers in Nigeria to make your dream come true. ${appName} simplifies the process,
                //     allowing you to pay your mortgage conveniently and securely.`
                // },
                {
                    iconName: 'bi-card-list',
                    iconColor: 'green',
                    title: 'Your Data is Secure',
                    body: `We have tightened up loose ends to ensure all transactions carried out are safe 
                    and secure by implementing:<br/>
                    Bank-level security<br/>
                    End-to-end encryption.`

                } 
            ],
        }
    },
    computed: {
        amount() {
            return !this.checked ? 'I want to spend' : 'I want to get';
        }
    },
    methods: {
        showSell() {
            this.sell = true;
        },
        showBuy() {
            this.sell = false;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
